import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="tv-section8">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Some common setup questions</h2>
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                    I waited 15 mins and the 5 lights are not lit, what do I do?
                                </AccordionHeader>
                                <AccordionBody>
                                    Sorry it's not working for you on your first try. This could mean that the coax port you're plugged into isn't live or is disconnected somewhere. If you've already tried other coax ports in your home, try tracing the line back to see where it might be hooked. Sometimes, the line could be cut, in which case we would need to send a technician to assist. Other times, all the coax ports lead to a communications panel where it just needs to be plugged in. We can assist if you send us a photo from inside the communications panel.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                    My device says I'm connected to the internet however nothing loads.
                                </AccordionHeader>
                                <AccordionBody>
                                    No worries. Take a look at your modem, are all five lights lit? Normally if you can not load anything it’s because your modem is not connected to a coax port that is connected to the internet. See if you can find another coax port to plug into or feel free to try to following this coax line through your home to see if it's cut or unhooked somewhere. Let us know if you find the line cut, we can help!                              
                                
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    I have my wifi setup however I am seeing two different networks. Which one do I connect to? 
                                </AccordionHeader>
                                <AccordionBody>
                                    After setting up your WiFi, you'll notice two different networks. One is labeled with '5G' at the end, which is ideal for devices in the same room as the modem as it often doesn't travel through walls well but carries a lot of bandwidth. The other network extends farther and penetrates walls more effectively. Feel free to use the network that works best for each device in your home.                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                    What if one of my devices can not see my Purple Cow network?
                                </AccordionHeader>
                                <AccordionBody>
                                    Feel free to unplug and plug your device and modem back in. That often does the trick. 
                                </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion
